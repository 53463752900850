/* required styles */
@import "../global/mixins";
/* stylelint-disable */
.keyboard {
  width: 100%;
  height: 470px;
  @include media-w-810{
    height: 323px;
  }
  &-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--color-white-light);
    padding: 4px
  }
}

.hg-theme-default .hg-button {
  height: 110px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #181d27;
  padding: 8px 2px 5px;
  isolation: isolate;
  background: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 27%);
  border-radius: 6px;
  border-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-w-810 {
    height: 75px
  }
  @include media-w-700 {
    font-size: 25px;
    line-height: 25px;
  }
}

.hg-theme-default .hg-row {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  gap: 4px;
  margin-bottom: 10px;

  @include media-w-810 {
    margin-bottom: 5px;
  }

  &:nth-child(1) {
    grid-template-columns: repeat(13, 1fr);
  }

  &:last-child {
    grid-template-columns: 1fr 1fr 6.4fr 1fr 1fr 1fr;
    margin-bottom: 0;
  }
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="@"] {
  max-width: 100%;
}

.hg-theme-default.hg-layout-numeric .hg-button {
  align-items: center;
  display: flex;
  height: 110px;
  justify-content: center;
  width: 100%;
}

.hg-theme-numeric .hg-row {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 4px;

  &:nth-child(1) {
    grid-template-columns: repeat(10, 1fr);
  }

  &:last-child {
    grid-template-columns: 1fr 1fr 6.4fr 1fr 1fr 1fr;
  }
}

.hg-theme-english .hg-row,
.hg-theme-englishBig .hg-row {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 4px;
  &:first-child {
    grid-template-columns: repeat(10, 1fr);
  }

  &:last-child {
    grid-template-columns: 1fr 1fr 6.4fr 1fr 1fr 1fr;
  }
}

.hg-theme-default .hg-button.hg-standardBtn,
.hg-theme-shift .hg-button.hg-standardBtn,
.hg-theme-english .hg-button.hg-standardBtn,
.hg-theme-englishBig .hg-button.hg-standardBtn {
  width: 100%;
}

.hg-theme-default .hg-row .hg-button-container,
.hg-theme-default .hg-row .hg-button:not(:last-child) {
  margin-right: 0;
}

.hg-button-capslock,
.hg-button-backspace,
.hg-button-controlleft,
.hg-button[data-skbtn="."],
.hg-button[data-skbtn=","] {
  background-color: var(--color-gray-light) !important;
}

.hg-button-altright {
  background: var(--color-dark) !important;
}

/* stylelint-enable */