@font-face {
  font-family: Muller;
  font-style: normal;
  font-weight: 700;
  src:
    local("Muller"),
    url("./MullerBold.woff2") format("woff2"),
    url("./MullerBold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

  font-display: swap;
}

@font-face {
  font-family: Muller;
  font-style: normal;
  font-weight: 500;
  src:
    local("Muller"),
    url("./MullerMedium.woff2") format("woff2"),
    url("./MullerMedium.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

  font-display: swap;
}

@font-face {
  font-family: Muller;
  font-style: normal;
  font-weight: 400;
  src:
    local("Muller"),
    url("./MullerRegular.woff2") format("woff2"),
    url("./MullerRegular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

  font-display: swap;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src:
    local("Roboto"),
    url("./Roboto-Regular.woff2") format("woff2"),
    url("./Roboto-Regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

  font-display: swap;
}

// НЕ нашел Русской раскладки для этого шрифта, так что ток латиница
@font-face {
  font-family: Actor;
  font-style: normal;
  font-weight: 400;
  src:
    local("Actor"),
    url("./ActorRegular.woff2") format("woff2"),
    url("./ActorRegular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */

  font-display: swap;
}
