.container {
  display: flex;
  flex-direction: column;
  gap: 60px;

  @include media-w-780 {
    gap: 50px;
  }

  @include media-w-700 {
    gap: 40px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__submit {
    margin-top: 60px;

    @include media-w-700 {
      margin-top: 40px;
    }
  }
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  &__button {
    padding: 40px;
    border-radius: 20px;
    border: none;
    background-color: var(--color-white);

    &_active {
      background-color: var(--color-orange);
    }
  }

  &__label {
    text-align: left;
    color: var(--color-dark);

    &_active {
      color: var(--color-white);
    }
  }
}

.spinner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
