:root {
  --color-black: #000;
  --color-dark: #181d27;
  --color-white: #fff;
  --color-white-light: #eff1f5;
  --color-white-middle: #e8eaed;
  --color-white-dark: #c5c7c9;
  --color-gray-dark: #3d3d3f;
  --color-gray-medium: #666;
  --color-gray: #74777d;
  --color-gray-extra-light: #dadce0;
  --color-gray-light: #ccced5;
  --color-orange: #f80;
  --color-yellow: #f7c564;
  --color-yellow-light: #ffc961;
  --color-yellow-circle: #ffb931;
  --color-red: #f54848;
  --color-violet: #9747ff;
  --color-blue: #477bff;
  --color-light-blue: #67bfff;
  --color-green: #6bda61;
  --font-primary: "Muller", sans-serif;
  --gx-spacing-x-small: 0;
  --gx-toggle-size: 80px;
  --color-connect: var(--color-dark);
  --height-input: 116px;
}
